.mainSmartDiagnostics {
    margin-left: 1%;
}

.textMainSmartDiagnostics {
    color: #0dfff0;
    font-size: 1.5vw;
    padding-top: 2%;
    width: 70%;
}

.containerSmartDiagnostics {
    display: flex;
    margin-top: 3%;
    width: 35%;
}

.containerSmartDiagnosticsLeft {
    width: 50%;
}

.containerSmartDiagnosticsRight {
    padding-right: 5%;
    width: 50%;
}

.containerSmartDiagnosticsTop {
    width: 20%;
}

.containerSmartDiagnosticsBottom {
    color: #0dfff0;
    font-size: 1.3vw;
    padding-top: 2%;
}

.backgroundSmartDiagnostic {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 40%;
    z-index: -1;
}

.buttonSmartDiagnostics {
    border: solid medium #0dfff0;
    display: flex;
    margin-top: 3%;
    width: 35%;
}

.buttonSmartDiagnosticsLeft {
    color: #0dfff0;
    font-size: 1vw;
    padding: 3%;
    width: 80%;
}

.buttonSmartDiagnosticsRight {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 20%;
}

.buttonSmartDiagnosticsRightImage {
    display: flex;
    height: 20%;
    width: 35%;
}

.linkBack {
    height: 9%;
    position: fixed;
    right: 10%;
    top: 5.5%;
    width: 4.5%;
}

.itemDTCTopRight {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1vw;
    font-weight: bold;
    justify-content: center;
    padding-left: 5%;
    padding-right: 1%;
    word-break: break-word;
}

.containerDTCPanel {
    display: flex;
    margin-top: 0;
    width: 90%;
}

.containerDTCPanelLeft {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1.1vw;
    justify-content: center;
    padding-right: 1%;
    width: 55%;
    word-break: break-word;
}

.containerDTCPanelRight {
    width: 35%;
}

.itemDTCTopLeft {
    width: 15%;
}

.itemDTCTopRight {
    align-items: center;
    display: flex;
    justify-content: left;
    width: 85%;
    word-break: break-word;
}

.itemDTCBottom {
    color: #0dfff0;
    font-size: 1vw;
    padding-right: 5%;
    padding-top: 2%;
    word-break: break-word;
}
