.bottomPlayerLeft2 {
    color: #0dfff0;
    display: flex;
    font-size: 1.3vw;
    height: fit-content;
    padding-left: 1%;
    width: 70%;
}

.bottomPlayerRight2 {
    color: #0dfff0;
    display: flex;
    font-size: 1.3vw;
    height: fit-content;
    padding-left: 5%;
    padding-top: 2%;
    width: 30%;
}

.blockBottomPlayer2 {
    display: flex;
    height: 25vh;
    width: 100%;
}
