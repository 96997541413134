.backgroundDefaultMaintenanceService {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: -1;
}

.leasingGrid {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
}

.leasingGrid__advantages {
    display: flex;
    flex-direction: column;
}

.leasingGrid__advantages__item {
    display: flex;
}

.leasingGrid__advantages__item > div {
    margin-bottom: 4vh;
}
