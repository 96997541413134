.backgroundCompetenceCenter {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 40%;
    z-index: -1;
}

.backgroundFleetManagement {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 20%;
    z-index: -1;
    display: flex;
}

.text1FleetManagment {
    color: #0dfff0;
    font-size: 1.3vw;
    margin-top: 1%;
    width: 70%;
}

.text2FleetManagment {
    color: #0dfff0;
    font-size: 1.2vw;
    margin-top: 1%;
    width: 70%;
}
