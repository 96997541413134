.mainSmartDiagnostics {
    margin-left: 1%;
}

.textMainSmartDiagnostics {
    color: #0dfff0;
    font-size: 1.5vw;
    padding-top: 2%;
    width: 70%;
}

.containerSmartDiagnostics {
    display: flex;
    margin-top: 3%;
    width: 35%;
}

.containerSmartDiagnosticsLeft {
    width: 50%;
}

.containerSmartDiagnosticsNext {
    width: 30vw;
}

.containerSmartDiagnosticsRight {
    padding-right: 5%;
    width: 50%;
}

.containerSmartDiagnosticsTop {
    width: 3vw;
}

.containerSmartDiagnosticsBottom {
    color: #0dfff0;
    font-size: 1.3vw;
    padding-top: 2%;
}

.backgroundProfessionalService {
    bottom: 25%;
    position: fixed;
    right: 2%;
    width: 40%;
    z-index: -1;
}

.buttonSmartDiagnostics {
    border: solid medium #0dfff0;
    display: flex;
    margin-top: 3%;
    width: 35%;
}

.buttonSmartDiagnosticsLeft {
    color: #0dfff0;
    font-size: 1vw;
    padding: 3%;
    width: 80%;
}

.buttonSmartDiagnosticsRight {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 20%;
}

.buttonSmartDiagnosticsRightImage {
    display: flex;
    height: 20%;
    width: 35%;
}


.backgroundSericeContracts {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 30%;
    z-index: -1;
}
