.subTitle {
    color: #0dfff0;
    font-size: 1.5vw;
}

.imageBlock {
    position: absolute;
    height: fit-content;
    width: 70vw;
    right: 0;
    z-index: -1;
}

.containerConfigurator {
    display: flex;
    margin: 1%;

}

.containerConfiguratorLeft {
    height: 55vh;
    width: 70%;
}

.containerConfiguratorRight {
    color: #0dfff0;
    font-size: 1.6vh;
    height: 55vh;
    margin-left: 3%;
    margin-right: 3%;
    position: relative;
    width: 25%;
}

.blockPlayer {
    background-color: rgba(0, 0, 0, 0.171);
    height: 55vh;
}

.blockBottomPlayer {
    display: flex;
    height: 25vh;
    padding-top: 3%;
    width: 100%;
}

.bottomPlayerLeft {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1.2vw;
    height: fit-content;
    padding-right: 1%;
    width: 65%;
}

.bottomPlayerRight {
    color: #0dfff0;
    display: flex;
    font-size: 1.1vw;
    height: fit-content;
    width: 35%;
}

.bottomPlayerOne {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1.2vw;
    height: fit-content;
    width: 100%;
}

.bottomPlayerRightLeft {
    align-items: center;
    display: flex;
}

.bottomPlayerRightRight {
    align-items: center;
    display: flex;
    padding-left: 5%;
}

.containerConfiguratorRightTop {
    font-size: 1.6vh;
    height: 65%;
    position: absolute;
    top: 0;
}

.containerConfiguratorRightBottom {
    bottom: 0;
    display: flex;
    position: absolute;
    width: 120%;
}

.containerConfiguratorButtonBlock {
    margin-right: 7%;
}

.containerConfiguratorButton {
    align-items: center;
    border: solid medium #0dfff0;
    border-radius: 20vw;
    color: #0dfff0;
    display: flex;
    font-size: 1vw;
    height: 3vh;
    padding: 1vh 1vw;
    text-decoration: none;
    white-space: nowrap;
    width: fit-content;
}

.digitStep {
    width: 5vw;    
}


