.linkmain {
    height: 11%;
    position: fixed;
    right: 3%;
    top: 4%;
    width: 5%;
}

.linkBack {
    height: 9%;
    position: fixed;
    right: 10%;
    top: 5.5%;
    width: 4.5%;
}

.headerCenterTitle {
    align-items: flex-end;
    color: #0dfff0;
    display: flex;
    justify-content: left;
    overflow: hidden;
    padding-left: 5%;
    width: 70%;
}

.headerlogo1 {
    margin-left: 2%;
    margin-top: 2%;
    text-align: center;
}

.titleSubmain {
    font-size: 2.5vw;
    font-weight: bold;
}

.buttonWithArrowContainer {
    border: solid medium #0dfff0;
    display: flex;
    height: 7vh;
    margin-top: 1%;
    width: 35%;
}

.buttonWithArrowContainerLeft {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1vw;
    padding: 3%;
    width: 80%;
}

.buttonWithArrowContainerRight {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 10%;
}

.buttonWithArrowContainerRightImage {
    display: flex;
    width: 10vw;
}


