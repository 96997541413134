.containerDefaultMaintenanceService {
    display: flex;
    margin-top: 2%;
    width: 100%;
    z-index: 10;
}

.itemDefaultMaintenanceService {
    width: 30%;
}

.itemDefaultMaintenanceServiceTop {
    display: flex;
}

.itemDefaultMaintenanceServiceTopLeft {
    width: 15%;
}

.itemDefaultMaintenanceServiceTopRight {
    align-items: center;
    color: #0dfff0;
    display: flex;
    font-size: 1.2vw;
    font-weight: bold;
    justify-content: center;
    padding-left: 5%;
    padding-right: 10%;
    word-break: break-word;
}

.itemDefaultMaintenanceServiceToBottom {
    color: #0dfff0;
    font-size: 1vw;
    padding-top: 2%;
}

.backgroundDefaultMaintenanceService {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: -1;
}
