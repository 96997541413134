#backVideo {
    height: 100%;
    left: 0;
    -o-object-fit: fill;
    object-fit: fill;
    position: fixed;
    top: 0;
    width: 100%;
}

.contentPage {
    display: flex;
    width: 100%;
}

.submenu {
    margin-left: 5%;
    margin-top: 3%;
    text-align: left;
    width: 12%;
}

.submenuitem {
    align-items: center;
    border: solid medium #0dfff0;
    display: flex;

    height: 6vh;
    justify-content: center;
    margin-bottom: 6%;
    padding: 5%;
    text-decoration: none;
    width: 100%;
}

.submenuitemselect {
    align-items: center;
    background-color: #0dfff0;
    border: solid medium #0dfff0;
    color: black;
    display: flex;
    height: 6vh;
    justify-content: left;
    margin-bottom: 5%;
    padding-bottom: 5%;
    padding-left: 0;
    padding-right: 5%;
    padding-top: 5%;
    text-decoration: none;
    width: 115%;
}

.submenuitemtext {
    color: #0dfff0;
    font-size: 1.2vw;
    text-align: center;
    word-break: break-word;
}

.submenuitemback {
    color: black;
    font-size: 1.4vw;
    font-weight: bold;

}

.submenuitembackblock {

    text-align: center;
    width: 100%;
}

.submenuitembacktext {
    color: black;
    font-size: 1.2vw;
}

.link {
    text-decoration: none;
}

.contentSubPage {
    margin-left: 5%;
    width: 75%;
}

.contentSubPageAnimation1 {
    animation-duration: 1s;
    animation-name: pageAnimation1;

}

.contentSubPageAnimation2 {
    animation-duration: 1s;
    animation-name: pageAnimation2;

}

@keyframes pageAnimation1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pageAnimation2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.imageFill {
    height: 100%;
    object-fit: fill;
    width: 100%;
}

.textMain {
    color: #0dfff0;
    font-size: 1.5vw;
    padding-top: 4%;
}

.textMainNext {
    color: #0dfff0;
    font-size: 1.5vw;

    padding-top: 2%;
}

.subTitle {
    color: #0dfff0;
    font-size: 1.6vw;
    font-weight: bold;
    margin: 1%;
}
