.backgroundDevelopmentServices {
    bottom: 0;
    position: fixed;
    right: 0;
    width: 11%;
    z-index: -1;
    display: flex;
}

.textDevelopmentServices {
    color: #0dfff0;
    font-size: 1.3vw;
    margin-top: 2%;
    width: 70%;
}

.text2DevelopmentServices {
    color: #0dfff0;
    font-size: 1.3vw;
    font-stretch: 200%;
    font-weight: bold;
    margin-top: 0;
    width: 100%;
}

.blockQRDS {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 30%;
}

.buttonBlockDS {
    margin-top: 5%;
}
