.textRightLP {
    color: #0dfff0;
    font-size: 1.3vw;
    padding-left: 2%;
    width: 50%;
}

.textMainLP {
    color: #0dfff0;
    font-size: 1.3vw;
    margin-bottom: 3%;
    margin-top: 5%;
}

.blockLP {
    display: flex;
    margin-bottom: 3%;
}

.imageLP {
    width: 25%
}
