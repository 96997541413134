.creditGrid {
    display: flex;
    margin-left: 1%;
}

.creditBlock {
    display: flex;
    flex-direction: column;
}

.creditBox {
    display: flex;
}

.creditBox > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.creditBox > div > div {
    margin-bottom: 4vh;
}

.banksListGrid {
    aspect-ratio: 3 / 4;
    background-color: white;
    border: 3px solid #0dfff0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-height: min(525px, 60.764vh);
    max-width: min(394px, 20.521vw);
    padding: 1vh;
}

.banksListGrid img {
    height: 5vh;
}
