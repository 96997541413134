h1 {
    color: #0dfff0;
    font-size: min(32px, 2vw);
}

h2 {
    color: #0dfff0;
    font-size: min(24px, 1.5vw);
    margin-bottom: 4vh;
}
