.financialGrid {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 5%;
}

.financialGrid h1 {
    color: #0dfff0;
    font-size: 48px;
}

.financialBlock {
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.financialBlockList {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.financialBlockItem {
    display: flex;
    flex-direction: column;
}

.financialBlockItem {
    margin-bottom: 8%;
}

.financialBlockItemText {
    color: #0dfff0;
    font-size: 3vh;
    margin-top: 3%;
}

.financialBlockItemImage {
    background-color: #002126;
    border-image: url("../../assets/icons/common/borderLeft.png") 0 fill;
    border-radius: 24px 0;
    padding: 1.2vw;
    width: 3.4vw;
}

.financialBlockList:last-child .financialBlockItemImage {
    border-image: url("../../assets/icons/common/borderRight.png") 0 fill;
    border-radius: 0 24px;
}

.financialBlockList:last-child .financialBlockItem {
    align-items: end;
}

.financialBlockList:last-child .financialBlockItem div {
    text-align: end;
}

.textMainFinancial {
    color: #0dfff0;
    font-size: 7vh;
}

.backgroundFinancial {
    bottom: 14%;
    position: fixed;
    right: 20%;
    width: 40%;
    z-index: -1;
}
