.backgroundDefaultTradein {
    bottom: 0;
    height: auto;
    position: fixed;
    right: 0;
    width: 55.6%;
    z-index: -1;
}

.leasingGrid > div {
    margin-bottom: 2vh;
    margin-left: 1%;
}
