#mainVideo {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    position: fixed;
    top: 7%;
    width: 100%;
}

.content {
    position: fixed;
    top: 0;
    width: 100%;
}

.headerCenter {
    margin-top: 2%;
    align-items: center;
    color: #0dfff0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 70%;
}

.headerlogo {
    margin-left: 2%;
    margin-top: 2%;
    text-align: center;
}

.headerlogo2 {
    margin-right: 2%;
    margin-top: 2%;
    text-align: center;
}

.logo {
    height: 100%;
    object-fit: fill;
    width: 100%;
}

.menu1 {
    height: 100%;
    object-fit: fill;
    width: 100%;
}

.title {
    object-fit: fill;
    width: 80%;
}

.header {
    display: flex;
    height: 17vh;
    width: 100%;
}

.menublock {
    height: 78vh;
    margin-top: 1vh;
}

.menu {
    display: flex;
    padding-bottom: 5%;
    width: 100%;
}

.menuleft {
    background-color: rgba(137, 43, 226, 0.274);
    height: 100%;
    position: relative;
    width: 50%;
}

.menuleftcontent {
    left: 10%;
    position: absolute;
    top: 5vh;
    width: 50%;
}

.menuright {
    height: 100%;
    position: relative;
    width: 50%;
}

.menurightcontent {
    position: absolute;
    right: 10%;
    top: 5vh;
    width: 50%;
}

.menuitem {
    margin-bottom: 8vh;
    width: 25vw;
}
